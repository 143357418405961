<template>
  <div>
    <VueText sizeLevel="6" weightLevel="3" class="description">
      Güvenlik sebebiyle şifrenizi güncellemeniz gerekmektedir. Lütfen şifrenizi kimseyle
      paylaşmayınız
    </VueText>
    <div id="divNewPasswordWrapper">
      <VueForm ref="form">
        <VueText sizeLevel="5" weightLevel="3" class="form-text" color="grey-40"
          >Yeni Şifre</VueText
        >
        <VueInput
          :type="passwordType"
          name="password"
          id="password"
          validatorName="Şifre"
          v-model="formModel.password"
          :messageOnTop="true"
          :inputLarge="true"
          ref="password"
          validationRules="required|max:20|min:8|atLeastTwoChar"
          :validState="true"
        >
          <VueButton
            :contentAlignment="constants.flexAlignment.center"
            :ghost="true"
            as="a"
            iconName="IconEye"
            :iconSize="{ width: getIcon.width, height: getIcon.height }"
            :iconColor="this.passwordType === 'password' ? '' : '#79838E'"
            @click="togglePassword"
          ></VueButton>
        </VueInput>
        <VueText sizeLevel="5" weightLevel="3" class="form-text" color="grey-40"
          >Yeni Şifreyi Tekrar Giriniz</VueText
        >
        <VueInput
          :type="passwordType"
          name="passwordRepeat"
          id="passwordRepeat"
          validatorName="Şifre"
          v-model="formModel.passwordRepeat"
          :messageOnTop="true"
          :inputLarge="true"
          validationRules="required|max:20|min:8|atLeastTwoChar|confirmed:password"
          :validState="true"
        >
          <VueButton
            :contentAlignment="constants.flexAlignment.center"
            :ghost="true"
            as="a"
            iconName="IconEye"
            :iconSize="{ width: getIcon.width, height: getIcon.height }"
            :iconColor="this.passwordType === 'password' ? '' : '#79838E'"
            @click="togglePassword"
          ></VueButton>
        </VueInput>
        <div slot="footer" class="button-wrapper">
          <BrandButton
            class="submit-button"
            :size="sizes.xxLarge"
            :contentAlignment="constants.flexAlignment.center"
            type="submit"
            :disabled="isDisabled"
            @click.prevent="submitPassword"
            >GÜNCELLE</BrandButton
          >
        </div>
      </VueForm>
      <BrandAuthFormFooter></BrandAuthFormFooter>
    </div>
  </div>
</template>

<script>
import VueText from '@/components/shared/VueText/VueText.vue';
import VueInput from '@/components/shared/VueInput/VueInput.vue';
import VueButton from '@/components/shared/VueButton/VueButton.vue';
import VueForm from '@/components/shared/VueForm/VueForm.vue';
import BrandButton from '@/components/brand/BrandButton/BrandButton.vue';
import BrandAuthFormFooter from '@/components/brand/BrandAuthFormFooter/BrandAuthFormFooter.vue';
import { Account } from '@/services/Api/index';
import { clearAppData } from '@/mixins/storageUtils.js';
import RoutesRoot from '@/router/routes/RoutesRoot.js';
import RoutesAthentication from '@/router/routes/RoutesAthentication.js';
import RoutesLogin from '@/router/routes/authentication/RoutesLogin.js';

import { ICON_VARIABLES } from '@/constants/component.constants.js';
import { FLEX_JUSTIFIES } from '@/constants/flex.constants.js';
import COMPONENT_CONSTANTS from '@/constants/component.constants.js';

export default {
  name: 'ExpiredPassword',
  components: {
    VueInput,
    VueButton,
    VueForm,
    BrandButton,
    VueText,
    BrandAuthFormFooter,
  },
  data: () => {
    return {
      isDisabled: true,
      formModel: {
        password: '',
        passwordRepeat: '',
      },
      passwordType: 'password',
      submitted: false,
    };
  },
  computed: {
    constants() {
      return {
        flexAlignment: FLEX_JUSTIFIES,
      };
    },
    sizes() {
      return COMPONENT_CONSTANTS.COMPONENT_SIZES;
    },
    getIcon() {
      return ICON_VARIABLES.eye;
    },
    minValue() {
      return this.formModel.password.length >= 8;
    },
    atLeast() {
      let list = 0;
      if (this.formModel.password.match(/[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/g)) {
        list++;
      }
      if (this.formModel.password.match(/[0-9]/g)) {
        list++;
      }
      if (this.formModel.password.match(/[a-z]/g)) {
        list++;
      }
      if (this.formModel.password.match(/[A-Z]/g)) {
        list++;
      }
      if (list > 1) {
        return true;
      }
      return false;
    },
  },
  methods: {
    togglePassword() {
      this.passwordType === 'password'
        ? (this.passwordType = 'text')
        : (this.passwordType = 'password');
    },
    async submitPassword() {
      this.submitted = true;

      const isValid = await this.$refs.form.$refs.observer.validate();
      if (!isValid) {
        return;
      } else {
        Account.updateExpiredPassword(this.formModel.password)
          .then(res => {
            if (res.data.Data.success && res.status) {
              clearAppData(
                'logout',
                `${RoutesRoot.Authentication.path}/${RoutesAthentication.Login.path}/${RoutesLogin.SelectLoginType.path}`,
              );
              return;
            }
            if (res.data.Error.Code === '500') {
              const errModalConfig = {
                isModalOpened: true,
                modalText: 'Şifre geçersiz lütfen tekrar deneyiniz.',
                noTitle: true,
              };
              this.$store.dispatch('app/setErrorModalConfig', errModalConfig);
            }
          })
          .catch(err => {
            console.log(err);
            const errModalConfig = {
              isModalOpened: true,
              modalText: 'Şifre geçersiz lütfen tekrar deneyiniz.',
              noTitle: true,
            };
            this.$store.dispatch('app/setErrorModalConfig', errModalConfig);
          });
      }
    },
  },
  created() {
    if (!this.$route.params.isAuth) {
      this.$router.push(
        `${RoutesRoot.Authentication.path}/${RoutesAthentication.Login.path}/${RoutesLogin.SelectLoginType.path}`,
      );
    }
  },
  watch: {
    formModel: {
      deep: true,
      handler() {
        if (
          this.atLeast &&
          this.minValue &&
          this.formModel.password === this.formModel.passwordRepeat
        ) {
          this.isDisabled = false;
        } else {
          this.isDisabled = true;
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.description {
  font-size: 28px;
  line-height: 1.43;
  color: #23303d;
  margin-top: palette-space-level(60);
  margin-bottom: palette-space-level(10);
}
.header {
  padding-top: palette-space-level('60');
}

.form-text {
  margin-top: palette-space-level('20');
  padding-bottom: palette-space-level('10');
}

/deep/ .button-wrapper {
  padding: palette-space-level('40') 0 palette-space-level('20');
  .submit-button {
    border-radius: palette-radius-level('2');
    span {
      color: #fff;
    }
  }
}
.small-header {
  padding-top: palette-space-level('10');
  padding-bottom: palette-space-level('10');
}
</style>
